import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { rhythm } from "../../utils/typography"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Services = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Why use video? - Services" />
      <article style={{ margin: `auto`, maxWidth: rhythm(34) }}>
        <header>
          <h1>Why use video</h1>
        </header>

        <Image
          fluid={data.header.childImageSharp.fluid}
          alt="Do something great"
          style={{
            maxWidth: `460px`,
            marginTop: rhythm(2),
            marginBottom: rhythm(2),
            marginLeft: `auto`,
            marginRight: `auto`,
          }}
        />

        <p>
          Video can offer a unique and personal brand experience, inform,
          inspire and entertain. It can build depth to a digital platform,
          generate a following of viewers and effectively communicate a message
          or brand identity
        </p>

        <div className="d-lg-flex">
          <Image
            fluid={data.videoStat1.childImageSharp.fluid}
            alt="84%"
            style={{
              width: `460px`,
              maxWidth: `100%`,
            }}
          />
          <Image
            fluid={data.videoStat2.childImageSharp.fluid}
            alt=""
            style={{
              width: `460px`,
              maxWidth: `100%`,
            }}
          />
          <Image
            fluid={data.videoStat3.childImageSharp.fluid}
            alt=""
            style={{
              width: `460px`,
              maxWidth: `100%`,
            }}
          />
        </div>

        <p>
          Video gives you the chance to speak directly to your audience from
          anywhere in the world, it evokes emotion and can easily be shared. It
          provides businesses with the opportunity to effectively communicate
          who they are and what they stand for. With social media channels
          playing an increasing role in promoting a brand or a cause, it allows
          them to showcase their products in more detail than any other tool
          available to marketing could.
        </p>

        <div className="d-lg-flex">
          <Image
            fluid={data.videoStat4.childImageSharp.fluid}
            alt="84%"
            style={{
              width: `460px`,
              maxWidth: `100%`,
            }}
          />
          <Image
            fluid={data.videoStat5.childImageSharp.fluid}
            alt=""
            style={{
              width: `460px`,
              maxWidth: `100%`,
            }}
          />
          <Image
            fluid={data.videoStat6.childImageSharp.fluid}
            alt=""
            style={{
              width: `460px`,
              maxWidth: `100%`,
            }}
          />
        </div>

        <div style={{ textAlign: `center` }}>
          <h2>Key types of video</h2>
          <p style={{ marginBottom: `0` }}>
            Promos | Brand Films | Editorial Content | Corporate Videos Event
            Coverage
          </p>
          <p>Product Demos | Tutorial and Explainer Videos</p>
        </div>

        <p>
          Whether you are a small business only just starting out, have already
          established yourself in the market, or are a big corporation looking
          for more video options in your marketing mix, I will work with you to
          create the most effective video for your business on message and on
          budget
        </p>

        <h2 style={{ textAlign: `center`, marginTop: `5rem` }}>
          A few of the happy clients I have worked with
        </h2>
        <Image
          fluid={data.logos.childImageSharp.fluid}
          alt="Some of my clients"
          style={{
            marginTop: rhythm(3),
            marginBottom: rhythm(3),
            width: `100%`,
            maxWidth: `100%`,
          }}
        />
      </article>
    </Layout>
  )
}

export default Services

export const pageQuery = graphql`
  query {
    header: file(absolutePath: { regex: "/do-something-great.jpg/" }) {
      childImageSharp {
        fluid(maxWidth: 1069) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    logos: file(absolutePath: { regex: "/client-logos.png/" }) {
      childImageSharp {
        fluid(maxWidth: 1069) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    videoStat1: file(absolutePath: { regex: "/video_stat_84_brand.png/" }) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    videoStat2: file(
      absolutePath: { regex: "/video_stat_96_product_service.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    videoStat3: file(
      absolutePath: { regex: "/video_stat_68_short_video.png/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    videoStat4: file(absolutePath: { regex: "/video_stat_92_marketing.png/" }) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    videoStat5: file(absolutePath: { regex: "/video_stat_24_marketing.png/" }) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    videoStat6: file(absolutePath: { regex: "/video_stat_80_sales.png/" }) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
